@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
// @import "./material-custom-theme.scss";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/styles/backgrounds/backgrounds.scss";
@import "./assets/styles/print.scss";
@import "./app/global-variables.scss";


// FONT AWESOME IS IMPLEMENTED VIA 'angular-fontawesome' and SVG fonts.  (see: https://github.com/FortAwesome/angular-fontawesome)
// Individual fonts/icons need to be imported (via pages.modules.ts), which will result in a smaller package for the user. ~ Mike A
// $fa-font-path : "~@fortawesome/fontawesome-free/webfonts";
// @import "~font-awesome/css/font-awesome.min.css";

@font-face {
   font-family: "Bree Serif";
   src: 
    url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/BreeSerif/breeserif-regular-webfont.woff2") format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
    url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/BreeSerif/breeserif-regular-webfont.woff") format('woff'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
    url("https://www.ifa.com/assets/fonts/BreeSerif/BreeSerif-Regular.ttf") format("truetype"); //Worse Format (too big in KB)
   font-display: swap; //Avoid showing invisible text 
 }

 @font-face {
   font-family: "Open Sans";
   src: url('assets/fonts/Font_Squirrel_Optimized/Open_Sans/opensans-variablefont_wdthwght-webfont.woff2') format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
        url('assets/fonts/Font_Squirrel_Optimized/Open_Sans/opensans-variablefont_wdthwght-webfont.woff') format('woff'), //IE Better > https://www.fontsquirrel.com/tools/webfont-generator
        url("https://www.ifa.com/assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype"); //Worse Format (too big in KB)
   font-display: swap; //Avoid showing invisible text 
 }
 @font-face {
   font-family: "Open Sans";
   
   src: url('https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Open_Sans/opensans-italic-variablefont_wdthwght-webfont.woff2') format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
        url('https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Open_Sans/opensans-italic-variablefont_wdthwght-webfont.woff') format('woff'), //IE Better > https://www.fontsquirrel.com/tools/webfont-generator
        url("https://www.ifa.com/assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf") format("truetype"); //Worse Format (too big in KB)
   font-display: swap; //Avoid showing invisible text 
   font-style:italic;
 }

 @font-face {
  font-family: "Oswald-Light";
  src: 
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Oswald/oswald-variablefont_wght-webfont.woff2") format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Oswald/oswald-variablefont_wght-webfont.woff") format('woff'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Oswald/static/Oswald-Light.ttf") format("truetype");
  font-display: swap; //Avoid showing invisible text 
}

@font-face {
  font-family: "Oswald-Bold";
  src: 
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Oswald/oswald-variablefont_wght-webfont.woff2") format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Oswald/oswald-variablefont_wght-webfont.woff") format('woff'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Oswald/static/Oswald-Bold.ttf") format("truetype");
  font-display: swap; //Avoid showing invisible text 
}

@font-face {
  font-family: "Langdon";
  src: 
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Langdon/langdon-webfont.woff2") format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/Langdon/langdon-webfont.woff") format('woff'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Langdon/static/langdon.otf") format("opentype");
  font-display: swap; //Avoid showing invisible text 
}

@font-face {
  font-family: "BebasNeue";
  src: 
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/BebasNeue/bebasneue-regular-webfont.woff2") format('woff2'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/BebasNeue/bebasneue-regular-webfont.woff") format('woff'), //Best > https://www.fontsquirrel.com/tools/webfont-generator
  url("https://www.ifa.com/assets/fonts/Font_Squirrel_Optimized/BebasNeue/BebasNeue-Regular.ttf") format("truetype");
  font-display: swap; //Avoid showing invisible text 
}

* {
  -webkit-font-smoothing: subpixel-antialiased;
}

 #toast-container > div {
   opacity:1;
}

a, .font-blue{
   color:$ifaBlue;
   word-break: break-word;
}
body{
   overflow-y: scroll; /* Show vertical scrollbar */
   color: $ifaBody;
   background-color: $ifaBodyBackground;
}
b, strong{
  font-weight: bold;
}

html {
  -webkit-text-size-adjust: 100%;
}

html,body
{
    margin:0;
    padding:0;
    height:100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}
.table{
  td,th{
    background-color: inherit;
  }
}

.width-100 {
   width: 100%;
}
h1{
    font-family:$ifaFontFamilyHeading;
}
 h2{
    font-size:1.2em;
 }
 h3{
    font-size:1.1em;
    font-weight: normal;
 }
 .font-30{
    font-size:30px;
 }
 .font-50 {
   font-size: 50px;
}
 .font-45 
 {
   font-size: 45px;
}
.font-40
 {
   font-size: 40px;
}
.font-35 
{
   font-size: 35px;
}
.font-34
{
   font-size: 34px;
}
.font-24
{
  font-size: 24px;
}

.font-20
{
  font-size: 20px;
}
.font-18 {
   font-size: 18px;
}
.font-16 {
   font-size: 16px;
}
.font-15 {
   font-size: 15px;
}
.font-13 {  font-size: 13px;}

 .font-bree{
    font-family: 'Bree Serif',serif !important;
 }
 .font-d-blue {
   color: $ifaDarkBlue;
 }
 .font-w {
   color: $white;
 }

.home-green-button {
   background-color: $ifaGreen;
   border: none;
   text-align: center;
   color: white;
   font-weight: 300;
   font-size: 20px;
   padding: 20px 0 !important;
   border-radius: 6px;
   display: block;
   text-decoration: none !important;
   width: 100%;
   margin-bottom: 25px;
   -moz-transition: 0.25s;
   -o-transition: 0.25s;
   -webkit-transition: 0.25s;
   transition: 0.25s;

   &:hover, :active, :focus {
      background-color: $ifaGreenHover;
      text-decoration: none !important;
      color: white !important;
      -moz-transition: 0.25s;
      -o-transition: 0.25s;
      -webkit-transition: 0.25s;
      transition: 0.25s;
      cursor: pointer;
   }
}
.green-button-box {
   max-width: 380px;
   min-width: 250px;
   margin: 0 auto;
}
.bottom-translucent-bar {
   background-color: rgba(0, 0, 0, 0.2);
   height: 85px;
   vertical-align: bottom;
}


//Italic Placeholder Text (START)
::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;  
 }
 ::-moz-placeholder {
    font-style: italic;  
 }
 :-ms-input-placeholder {  
    font-style: italic; 
 }
 //Italic Placeholder Text (END)

.btn-primary{
   background-color: $ifaBlue;
}
.btn-success{
   background-color: $ifaGreen;
}

.udline-no{ text-decoration: none; }

.green-button 
{
   background-color: #389237;
   border: none;
   padding: 15px;
   text-align: center;
   color: #fff;
   text-decoration: none;
   transition: all;
   animation: background-color 1s;
   &:visited,&:hover{
    color:#fff;
   }
   &:hover{
    background-color:#389237;
   }
}
.width-200px
{
    width:200px;
}
.width-250
{
    width:250px;
}
.width-300px
{
    width:300px;
}

.center-block {   display: block; margin-left: auto;   margin-right: auto;}
.font-18{    font-size: 18px;}


.award-column .laurel-award {
   width: 200px;
   margin: 30px auto 5px; }
   @media only screen and (min-width: 768px) {
     .award-column .laurel-award {
       width: 245px;
       margin: 30px auto; } }
   @media only screen and (min-width: 992px) {
     .award-column .laurel-award {
       width: 100%; } }
   @media only screen and (min-width: 1200px) {
     .award-column .laurel-award {
       width: 100%; } }
   .award-column .laurel-award .full-award-holder {
     width: 100%; }
     @media only screen and (min-width: 992px) {
       .award-column .laurel-award .full-award-holder {
         width: 200px;
         float: left;
         margin: 30px 25px 5px 0; } }
     @media only screen and (min-width: 1200px) {
       .award-column .laurel-award .full-award-holder {
         width: 245px;
         float: left; } }
     .award-column .laurel-award .full-award-holder .award-content {
       width: calc(100% - 40px*2);
       float: left;
       text-align: center;
       font-size: 15px; }
     @media only screen and (max-width: 767px) {
         .award-column .laurel-award .full-award-holder .award-content {
             position:relative;
             bottom:10px;} }
       @media only screen and (min-width: 768px) {
         .award-column .laurel-award .full-award-holder .award-content {
           font-size: 18px; } }
       @media only screen and (min-width: 992px) {
         .award-column .laurel-award .full-award-holder .award-content {
           font-size: 15px; } }
       @media only screen and (min-width: 1200px) {
         .award-column .laurel-award .full-award-holder .award-content {
           font-size: 18px; } }
       .award-column .laurel-award .full-award-holder .award-content .laurel-award-title {
         font-family: 'Bree Serif', serif;
         font-size: 16px; }
         @media only screen and (min-width: 768px) {
           .award-column .laurel-award .full-award-holder .award-content .laurel-award-title {
             font-size: 18px; } }
         @media only screen and (min-width: 992px) {
           .award-column .laurel-award .full-award-holder .award-content .laurel-award-title {
             font-size: 16px; } }
         @media only screen and (min-width: 1200px) {
           .award-column .laurel-award .full-award-holder .award-content .laurel-award-title {
             font-size: 18px; } }
     .award-column .laurel-award .full-award-holder .laurel-leaf {
       width: 40px;
       height: 120px;
       position: relative; }
       @media only screen and (min-width: 768px) {
         .award-column .laurel-award .full-award-holder .laurel-leaf {
           height: 140px; } }
       @media only screen and (min-width: 992px) {
         .award-column .laurel-award .full-award-holder .laurel-leaf {
           height: 120px; } }
       @media only screen and (min-width: 1200px) {
         .award-column .laurel-award .full-award-holder .laurel-leaf {
           height: 130px; } }
       .award-column .laurel-award .full-award-holder .laurel-leaf img {
         position: absolute;
         display: block;
         width: 65px;
         height: 112px; }
         @media only screen and (min-width: 768px) {
           .award-column .laurel-award .full-award-holder .laurel-leaf img {
             width: 75px;
             height: 130px; } }
         @media only screen and (min-width: 992px) {
           .award-column .laurel-award .full-award-holder .laurel-leaf img {
             width: 65px;
             height: 112px; } }
         @media only screen and (min-width: 1200px) {
           .award-column .laurel-award .full-award-holder .laurel-leaf img {
             width: 75px;
             height: 130px; } }
       .award-column .laurel-award .full-award-holder .laurel-leaf.left-laurel {
         float: left; }
         .award-column .laurel-award .full-award-holder .laurel-leaf.left-laurel img {
           left: 0; }
       .award-column .laurel-award .full-award-holder .laurel-leaf.right-laurel {
         float: left; }
         .award-column .laurel-award .full-award-holder .laurel-leaf.right-laurel img {
           right: 0; }
 
 .award-logo {
     margin-top: 0;
     margin-left: auto;
     margin-right: auto; 
     width: 132px;
    //  height: 60px; 
 }
   @media only screen and (min-width: 1200px) {
     .award-logo {
       clear: both;
       margin-top: 140px; } }
   .award-logo img {
     width: 100%;
     height: 100%; 
    max-height: 70px;
   }

.fc.ng-valid
{
    border-left: 4px solid #52b958 !important; 
}
.fc.ng-invalid:not(form)  
{
    border-left: 3px solid red !important; 
}
.errorHint { padding-left:10px; color: #962e12;}

.b {font-weight: 600 !important;}

.float-l {  float: left;}

.font-12 { font-size: 12px !important;}
.font-open-l {
  font-family: 'Open Sans',sans-serif !important;
  font-weight: 300 !important;
}

.mb-0 {margin-bottom: 0px;}
.mb-1 {margin-bottom: 1px;}
.mb-2 {margin-bottom: 2px;}
.mb-5 {margin-bottom: 5px;}
.mb-10 {margin-bottom: 10px;}
.mb-15 {margin-bottom: 15px;}
.mb-20 {margin-bottom: 20px;}
.mb-25 {margin-bottom: 25px;}
.mb-30 {margin-bottom: 30px;}
.mb-35 {margin-bottom: 35px;}
.mb-40 {margin-bottom: 40px;}
.mb-80 {margin-bottom: 80px;}
.mb-150 {margin-bottom: 150px;}
.ml-0 {margin-left: 0px;}
.ml-2 {margin-left: 2px;}
.ml-5 {margin-left: 5px;}
.ml-8 {margin-left: 8px; }
.ml-10 {margin-left: 10px;}
.ml-20 {margin-left: 20px;}
.ml-40 {margin-left: 40px;}
.mr-0 {margin-right: 0px;}
.mr-2 {margin-right: 2px}
.mr-3 {margin-right: 3px}
.mr-5 {margin-right: 5px}
.mr-8 {margin-right: 8px}
.mr-10 {margin-right: 10px}
.mr-20 {margin-right: 20px}
.mr-40 {margin-right: 40px}
.mt-0 {margin-top: 0px;}
.mt-2 {margin-top: 2px;}
.mt-5 {margin-top: 5px;}
.mt-6 {margin-top: 6px;}
.mt-8 {margin-top: 8px;}
.mt-10 {margin-top: 10px;}
.mt-15 {margin-top: 15px;}
.mt-20 {margin-top: 20px;}
.mt-25 {margin-top: 25px;}
.mt-30 {margin-top: 30px;}
.mt-40 {margin-top: 40px;}
.mt-50 {margin-top: 50px;}
.mt-80 {margin-top: 80px;}
.mt--10 {margin-top: -10px}

.pad-all-0 {  padding: 0px !important;}
.p-0 {  padding: 0px !important;}
.pb-5 {padding-bottom: 5px;}
.pb-10 {padding-bottom: 10px;}
.pb-15 {padding-bottom: 15px;}
.pb-20{padding-bottom: 20px}
.pb-40{padding-bottom: 40px}
.pl-0 {padding-left: 0px;}
.pl-8 {padding-left: 8px;}
.pl-10 {padding-left: 10px;}
.pl-80 {padding-left: 80px;}
.pl-90 {padding-left: 90px;}
.pr-0{padding-right: 0px}
.pr-20{padding-right: 20px}
.pr-45{padding-right: 45px}
.pt-5{padding-top: 5px!important}
.pt-15{padding-top: 15px}
.pt-20{padding-top: 20px}
.pt-40{padding-top: 40px}




.pull-left {float: left !important;}
.pull-right{float: right !important;}

.font-open {
  font-family: 'Open Sans',sans-serif !important;
  font-weight: normal;
}
.blue-button 
{
  background-color: #52b958;
  border: none;
  padding: 15px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.disable-blue-button 
{
    background-color: #c1c8cb;
    border: none;
    padding: 15px;
    color: gray; 
    text-align: center;
    text-decoration: none;
    pointer-events: none;
}

.pad-40 
{
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.pad-both-0 
{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.montecarloForm input[type=text], .montecarloForm input[type=number], .montecarloForm input[type=email] 
{
    height: 25px;
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid #ccc;
    padding: 3px;
    color: #006aa7;
    background-color: #f5f5f5;
    font-size: 16px;
    text-align: right;
}

.text-center { text-align: center; }

.call-oval-speech-border:before 
{
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -25px;
  left: 38%;
  height: 30px;
  border-right: 60px solid #237091;
  background: #237091;
  -webkit-border-bottom-right-radius: 80px 50px;
  -moz-border-radius-bottomright: 80px 50px;
  border-bottom-right-radius: 80px 50px;
  -webkit-transform: translate(0,-2px);
  -moz-transform: translate(0,-2px);
  -ms-transform: translate(0,-2px);
  -o-transform: translate(0,-2px);
  transform: translate(0,-2px);
}  
.call-oval-speech-border:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -30px;
  left: 38%;
  width: 60px;
  height: 30px;
  background: #fff;
  -webkit-border-bottom-right-radius: 50px 40px;
  -moz-border-radius-bottomright: 50px 40px;
  border-bottom-right-radius: 50px 40px;
  -webkit-transform: translate(-30px,-2px);
  -moz-transform: translate(-30px,-2px);
  -ms-transform: translate(-30px,-2px);
  -o-transform: translate(-30px,-2px);
  transform: translate(-30px,-2px);
}
.background-blue-trans-faux {
  background-color: #5da3bf;
}
.pos-rel {    position: relative;}
.charth1 {  font-size: 70px !important; }
.clearfix {
  zoom: 1;
  clear: both;
}
.breadcrumb {
  padding: 8px 0;
  margin-bottom: 20px;
  list-style: none;
  // background-color: #f5f5f5;
  border-radius: 4px;
  a{
    text-decoration: none;  
  }
}
.background-none {
  background-color: transparent !important;
}

.background-off-white {
  background-color: #f8f8f8;
}

.content 
{
  width: 80%;
  margin: 0 auto;
  background: #f8f8f8;
  padding: 50px 10px;
}

.float-right 
{
    float: right;
    margin: 0 auto;
}

html {

  scroll-behavior: auto !important
  
  }
.flarger{font-size:larger}
.qdisclaimer {font-style: italic;}
.global-div-btn
{
  background-color: #1075bd;
  color: white;
  font-weight: 100;
}
.global-div-btn:hover
{
  background-color: #03aef1;
  color: white;
  font-weight: 600;
}